import React, {useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import ToggleRadioButton from '../../components/toggleBtn/ToggleButton';
import ToggleButton from '../../components/toggleButton';
import {removeCircleIcon, checkedCircleIcon, quetionIcon, closeIcon} from '../../img/svgIcons/index';
// import ReactTooltip from 'react-tooltip';
import pricingIcon from '../../img/pricing/pricing-hexometer.png';
import {GET_HEXOMETER_USER_SETTINGS, ME} from '../../graphql/queries';
import {useLazyQuery, useQuery} from '@apollo/client';
import {Modal} from '../../components/modal';
import Button from '../../components/button';
import EmailVerification from '../../components/payment-add-more/emailVerification';
import './styles.scss';
import Timer from '../../components/timer';
import {getLTDPromo249, setLTDPromo249} from '../../helpers';

interface ICard {
  productName: string;
  amount: number;
  id: string;
  interval: string;
  maxStackCount: string;
  max_frequency: string;
  isPopular: boolean;
  priceId: string;
  description: {
    page_limit_per_website: string;
    agency_growth_tool: string;
    availability_monitoring: string;
    max_properties_count: string;
  };
  productType: 'STRIPE' | 'PAYPAL';
}
const pricing = [
  {
    packageName: null,
    price: null,
    uniqueHostName: 'Monitored websites',
    page_limit_per_website: 'Page limit per website',
    smart_crawl: 'Smart crawl',
    on_demand_reports: 'On-demand reports',
    availability_monitoring: {
      title: 'Availability monitoring',
      uptime_monitoring_frequency: 'Uptime monitoring frequency',
      standard_uptime_monitors: 'Standard uptime monitors',
      advanced_monitors: 'Advanced monitors',
      whitelabeled_status_pages: 'White-labeled status pages',
    },
    user_experience_monitoring: {
      title: 'User experience monitoring',
      spellcheck_and_grammar_checks: 'Spelling and grammar checks in 30+ languages',
      in_depth_multi_device_UX_checks: 'In depth multi-device UX checks',
    },
    performance_monitoring: {
      title: 'Performance monitoring',
      in_depth_performance_checks: 'In depth performance checks',
    },
    health_monitoring: {
      title: 'Health monitoring',
      broken_links_error_monitoring: 'Broken links & error monitoring',
      in_depth_W3C_JS_error_checks: 'In depth W3C & JS error checks',
    },
    seo_monitoring: {
      title: 'SEO monitoring',
      search_engine_optimization_audit: 'Search engine optimization audit',
      google_search_console: 'Google search console',
    },
    security_monitoring: {
      title: 'Security monitoring',
      domain_level_web_safe_security_audit: 'Domain level web safe & security audit',
      iP_address_blacklist_checks: 'IP address blacklist checks',
    },
    notification_channels_integrations: {
      title: 'Notification channels & integrations',
      Email: ' Email',
      Telegram_slack_trello_discord: ' Telegram, Slack, Trello, Discord',
      webhooks_zapier_pabbly_integrately: ' Webhooks, Zapier, Pabbly Connect, Integrately, SyncSpider',
      // sms: ' SMS',
    },
    reporting: {
      title: 'Reporting',
      exports_CSV_G_Sheets_PDF_whitelabeled: 'Exports to: CSV, G Sheets, PDF white-labeled',
    },
  },
  {
    packageName: 'Standard',
    price: '20',
    uniqueHostName: 2,
    page_limit_per_website: 10000,
    agency_growth_tools: 50,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 5,
      advanced_monitors: false,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '50 / mo',
      in_depth_multi_device_UX_checks: '10 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '50 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '50 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 2,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '10 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
  {
    packageName: 'Advanced',
    price: '50',
    uniqueHostName: 3,
    page_limit_per_website: 25000,
    agency_growth_tools: 100,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 10,
      advanced_monitors: 1,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '100 / mo',
      in_depth_multi_device_UX_checks: '15 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '100 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '100 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 10,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '30 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
  {
    packageName: 'Guru',
    price: '100',
    uniqueHostName: 5,
    page_limit_per_website: 50000,
    agency_growth_tools: 150,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 25,
      advanced_monitors: 2,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '200 / mo',
      in_depth_multi_device_UX_checks: '25 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '200 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '200 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 25,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '50 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
];

const pricingAgency = [
  {
    packageName: null,
    price: null,
    uniqueHostName: 'Monitored websites',
    page_limit_per_website: 'Page limit per website',
    agency_growth_tools: 'Agency growth tools',
    smart_crawl: 'Smart crawl',
    on_demand_reports: 'On-demand reports',
    availability_monitoring: {
      title: 'Availability monitoring',
      uptime_monitoring_frequency: 'Uptime monitoring frequency',
      standard_uptime_monitors: 'Standard uptime monitors (1 minute checks)',
      advanced_monitors: 'Advanced monitors',
      whitelabeled_status_pages: 'White-labeled status pages',
    },
    user_experience_monitoring: {
      title: 'User experience monitoring',
      spellcheck_and_grammar_checks: 'Spelling and grammar checks in 30+ languages',
      in_depth_multi_device_UX_checks: 'In depth multi-device UX checks',
    },
    performance_monitoring: {
      title: 'Performance monitoring',
      in_depth_performance_checks: 'In depth performance checks',
    },
    health_monitoring: {
      title: 'Health monitoring',
      broken_links_error_monitoring: 'Broken links & error monitoring',
      in_depth_W3C_JS_error_checks: 'In depth W3C & JS error checks',
    },
    seo_monitoring: {
      title: 'SEO monitoring',
      search_engine_optimization_audit: 'Search engine optimization audit',
      google_search_console: 'Google search console',
    },
    security_monitoring: {
      title: 'Security monitoring',
      domain_level_web_safe_security_audit: 'Domain level web safe & security audit',
      iP_address_blacklist_checks: 'IP address blacklist checks',
    },
    notification_channels_integrations: {
      title: 'Notification channels & integrations',
      Email: ' Email',
      Telegram_slack_trello_discord: ' Telegram, Slack, Trello',
      webhooks_zapier_pabbly_integrately: ' Webhooks, Zapier, Pabbly Connect, Integrately, SyncSpider',
      // sms: ' SMS',
    },
    reporting: {
      title: 'Reporting',
      exports_CSV_G_Sheets_PDF_whitelabeled: 'Exports to: CSV, G Sheets, PDF white-labeled',
    },
  },

  {
    packageName: 'Agency Standard',
    price: '20',
    uniqueHostName: 10,
    page_limit_per_website: 10000,
    agency_growth_tools: 50,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 30,
      advanced_monitors: false,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      spellcheck_and_grammar_checks: '150 / mo',
      in_depth_multi_device_UX_checks: '50 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '200 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '200 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 50,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '25 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
  {
    packageName: 'Agency Advanced',
    price: '50',
    uniqueHostName: 15,
    page_limit_per_website: 25000,
    agency_growth_tools: 100,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 40,
      advanced_monitors: 4,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '250 / mo',
      in_depth_multi_device_UX_checks: '75 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '450 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '450 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 100,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '50 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
  {
    packageName: 'Agency Guru',
    price: '100',
    uniqueHostName: 20,
    page_limit_per_website: 50000,
    agency_growth_tools: 150,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 60,
      advanced_monitors: 6,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '500 / mo',
      in_depth_multi_device_UX_checks: '100 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '750 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '750 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 200,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '75 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
];

export const newPackageDescriptions: any = [];

const Pricing = ({pageContext}: any) => {
  const ltd = false;
  const promo = false;
  const [showPaypalMessage, setShowPaypalMessage] = useState(false);
  const [paypal_subscription_id, setPaypalSubscriptionId] = useState('');
  const [stripe_subscription_id, setStripeSubscriptionId] = useState('');
  const [annually, setAnnually] = useState(true);
  const [pendingPayment, setPendingPayment] = useState(false);
  const [agency, setAgency] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [pr, setPr] = useState(pricing);
  const [currentPackage, setCurrentPackage] = useState('');
  const [currentPackageInterval, setCurrentPackageInterval] = useState('');
  const [showUpgradeContact, setShowUpgradeContact] = useState(true);
  const [user, setUser] = useState(null as any);
  const [item, setItem] = useState(pricing[0]);
  const [selectedPackage, setSelectedPackage] = useState({} as any);
  const [showLTD, setShowLTD] = useState(true);
  const [showTrial, setShowTrial] = useState(true);
  const [trialList, setTrialList] = useState<any>(['STANDARD']);
  const [hexometerPackages, setHexometerPackages] = useState<{
    annual: any;
    monthly: any;
    ltd: any;
  }>({annual: {}, monthly: {}, ltd: {}});

  const [updatedHexometerPackages, setUpdatedHexometerPackages] = useState<{
    annual: any;
    monthly: any;
    ltd: any;
  }>({annual: {}, monthly: {}, ltd: {}});
  const [showModal, setShowModal] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);

  const {data: userData, loading} = useQuery(ME);

  const [getHexometerUser, {data: hexometerUser}] = useLazyQuery(GET_HEXOMETER_USER_SETTINGS, {
    fetchPolicy: 'no-cache',
    variables: {user_id: user && user.id && +user.id},
  });

  useEffect(() => {
    navigate('/pricing/');
  }, []);

  useEffect(() => {
    if (!getLTDPromo249()) {
      setLTDPromo249('true');
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     user &&
  //     user.id &&
  //     user.verified &&
  //     localStorage.getItem('packageInfo') &&
  //     hexometerPackages.annual.Regular &&
  //     hexometerPackages.annual.Regular.length &&
  //     currentPackage
  //   ) {
  //     const id = localStorage.getItem('packageInfo');
  //     localStorage.removeItem('packageInfo');
  //     id && findPackeage(id);
  //   }
  // }, [user, hexometerPackages, currentPackage]);

  useEffect(() => {
    //@ts-ignore
    setItem(agency ? pricingAgency[0] : pricing[0]);
  }, [agency]);

  useEffect(() => {
    if (hexometerUser && hexometerUser.HexometerUserSettings && hexometerUser.HexometerUserSettings.get) {
      setPaypalSubscriptionId(hexometerUser.HexometerUserSettings.get.paypal_subscription_id);
      setStripeSubscriptionId(hexometerUser.HexometerUserSettings.get.stripe_subscription_id);
      setCurrentPackage(hexometerUser.HexometerUserSettings.get.pricing_package);
      setCurrentPackageInterval(hexometerUser.HexometerUserSettings.get.pricing_package_interval);
      setPendingPayment(!!hexometerUser.HexometerUserSettings.get.pending_payment);
      setTrialList(hexometerUser.HexometerUserSettings.get.trial_packages);
      typeof hexometerUser.HexometerUserSettings.get.can_use_trial === 'boolean' &&
        setShowTrial(hexometerUser.HexometerUserSettings.get.can_use_trial);
    }
  }, [hexometerUser]);

  useEffect(() => {
    if (userData && userData.User && userData.User.get) {
      setUser(userData.User.get);
    }
  }, [userData]);

  useEffect(() => {
    user && user.id && getHexometerUser();
  }, [user]);

  useEffect(() => {
    if (pageContext && pageContext.products) {
      const packages_annual: any = {};
      const packages_ltd: any = {};
      const packages: any = {};
      const paypal_packages_annual: any = {};
      const paypal_packages_ltd: any = {};
      const paypal_packages: any = {};
      pageContext.products.map((item: ICard) => {
        const planInfo = pageContext.products.find(
          (subItem: any) => subItem.interval === 'LTD' && subItem.productName === item.productName,
        );
        let el = {
          id: planInfo && item.interval === 'ANNUAL' ? planInfo.id : item.id,
          interval: item.interval,
          name: item.productName,
          price: item.amount,
          lineThrough: item.interval === 'LTD',
          trigger: item.interval === 'LTD' && item.amount,
          flag: item.isPopular,
          ltdPrice: planInfo && item.interval === 'ANNUAL' ? planInfo.amount : null,
          discount:
            promo && item.interval === 'ANNUAL'
              ? item.productName.includes('Standard')
                ? 30
                : item.productName.includes('Advanced')
                ? 40
                : item.productName.includes('Guru')
                ? 50
                : null
              : null,
          real_price:
            item.interval === 'ANNUAL'
              ? pageContext.products.find(
                  (subItem: any) => subItem.interval === 'MONTHLY' && subItem.productName === item.productName,
                ).amount
              : null,

          disabled: true,
        };

        if (item.productName === 'Standard') {
          el.disabled = !currentPackage || (currentPackage && currentPackage !== 'FREE') ? true : false;
        } else if (item.productName === 'Agency Standard') {
          el.disabled =
            item.interval === 'ANNUAL'
              ? currentPackage !== 'AGENCY_STANDARD' && currentPackageInterval === 'LTD'
                ? true
                : false
              : !currentPackage || (currentPackage && currentPackage !== 'FREE' && !currentPackage.includes('STANDARD'))
              ? true
              : false;
        } else if (item.productName === 'Advanced') {
          el.disabled =
            !currentPackage || (currentPackage && currentPackage !== 'FREE' && !currentPackage.includes('STANDARD'))
              ? true
              : false;
        } else if (item.productName === 'Agency Advanced') {
          el.disabled =
            item.interval === 'ANNUAL'
              ? currentPackage !== 'AGENCY_ADVANCED' && currentPackageInterval === 'LTD'
                ? true
                : false
              : !currentPackage ||
                (currentPackage &&
                  currentPackage !== 'FREE' &&
                  !currentPackage.includes('STANDARD') &&
                  !currentPackage.includes('ADVANCED'))
              ? true
              : false;
        } else if (item.productName === 'Guru') {
          el.disabled =
            !currentPackage ||
            (currentPackage &&
              currentPackage !== 'FREE' &&
              !currentPackage.includes('STANDARD') &&
              !currentPackage.includes('ADVANCED'))
              ? true
              : false;
        } else {
          el.disabled = currentPackage !== 'AGENCY_GURU' && currentPackageInterval === 'LTD' ? true : false;
        }
        //@ts-ignore
        const info = (item.productName.includes('Agency') ? pricingAgency : pricing).filter(
          (pr: any) => pr.packageName && item.productName.includes(pr.packageName),
        );
        //@ts-ignore

        if (info.length) el = {...info[0], ...el};

        if (item.productType === 'PAYPAL') {
          //@ts-ignore
          if (item.interval === 'ANNUAL') paypal_packages_annual[item.productName] = el;
          else if (item.interval === 'LTD') paypal_packages_ltd[item.productName] = el;
          else if (item.interval === 'MONTHLY' && item.priceId !== 'none') paypal_packages[item.productName] = el;
        } else {
          //@ts-ignore
          if (item.interval === 'ANNUAL') packages_annual[item.productName] = el;
          else if (item.interval === 'LTD') packages_ltd[item.productName] = el;
          else if (item.interval === 'MONTHLY' && item.priceId !== 'none') packages[item.productName] = el;
        }
      });
      setUpdatedHexometerPackages({
        annual: {
          Regular: [
            // packages.Free,
            {...packages_annual.Standard, paypal_id: paypal_packages_annual.Standard.id},
            {...packages_annual.Advanced, paypal_id: paypal_packages_annual.Advanced.id},
            {...packages_annual.Guru, paypal_id: paypal_packages_annual.Guru.id},
          ],
          Agency: [
            // packages.Free,
            {...packages_annual['Agency Standard'], paypal_id: paypal_packages_annual['Agency Standard'].id},
            {
              ...packages_annual['Agency Advanced'],
              paypal_id: paypal_packages_annual['Agency Advanced']
                ? paypal_packages_annual['Agency Advanced'].id
                : null,
            },
            {...packages_annual['Agency Guru'], paypal_id: paypal_packages_annual['Agency Guru'].id},
          ],
        },
        ltd: {
          Regular: [
            // packages.Free,
            {
              ...packages_ltd.Standard,
              paypal_id: paypal_packages_ltd.Standard ? paypal_packages_ltd.Standard.id : null,
            },
            {
              ...packages_ltd.Advanced,
              paypal_id: paypal_packages_ltd.Advanced ? paypal_packages_ltd.Advanced.id : null,
            },
            {...packages_ltd.Guru, paypal_id: paypal_packages_ltd.Guru ? paypal_packages_ltd.Guru.id : null},
          ],
          Agency: [
            // packages.Free,
            {
              ...packages_ltd['Agency Standard'],
              paypal_id: paypal_packages_ltd['Agency Standard'] ? paypal_packages_ltd['Agency Standard'].id : null,
            },
            {
              ...packages_ltd['Agency Advanced'],
              paypal_id: paypal_packages_ltd['Agency Advanced'] ? paypal_packages_ltd['Agency Advanced'].id : null,
            },
            {
              ...packages_ltd['Agency Guru'],
              paypal_id: paypal_packages_ltd['Agency Guru'] ? paypal_packages_ltd['Agency Guru'].id : null,
            },
          ],
        },
        monthly: {
          Regular: [
            // packages.Free,
            {...packages.Standard, paypal_id: paypal_packages.Standard.id, trial: true, name: 'Standard (7-day trial)'},
            {...packages.Standard, paypal_id: paypal_packages.Standard.id},
            {...packages.Advanced, paypal_id: paypal_packages.Advanced.id},
            {...packages.Guru, paypal_id: paypal_packages.Guru.id},
          ],
          Agency: [
            // packages.Free,
            {...packages['Agency Standard'], paypal_id: paypal_packages['Agency Standard'].id},
            {...packages['Agency Advanced'], paypal_id: paypal_packages['Agency Advanced'].id},
            {...packages['Agency Guru'], paypal_id: paypal_packages['Agency Guru'].id},
          ],
        },
      });
      setHexometerPackages({
        annual: {
          Regular: [
            // packages.Free,
            {...packages_annual.Standard, paypal_id: paypal_packages_annual.Standard.id},
            {...packages_annual.Advanced, paypal_id: paypal_packages_annual.Advanced.id},
            {...packages_annual.Guru, paypal_id: paypal_packages_annual.Guru.id},
          ],
          Agency: [
            // packages.Free,
            {...packages_annual['Agency Standard'], paypal_id: paypal_packages_annual['Agency Standard'].id},
            {
              ...packages_annual['Agency Advanced'],
              paypal_id: paypal_packages_annual['Agency Advanced']
                ? paypal_packages_annual['Agency Advanced'].id
                : null,
            },
            {...packages_annual['Agency Guru'], paypal_id: paypal_packages_annual['Agency Guru'].id},
          ],
        },
        ltd: {
          Regular: [
            // packages.Free,
            {
              ...packages_ltd.Standard,
              paypal_id: paypal_packages_ltd.Standard ? paypal_packages_ltd.Standard.id : null,
            },
            {
              ...packages_ltd.Advanced,
              paypal_id: paypal_packages_ltd.Advanced ? paypal_packages_ltd.Advanced.id : null,
            },
            {...packages_ltd.Guru, paypal_id: paypal_packages_ltd.Guru ? paypal_packages_ltd.Guru.id : null},
          ],
          Agency: [
            // packages.Free,
            {
              ...packages_ltd['Agency Standard'],
              paypal_id: paypal_packages_ltd['Agency Standard'] ? paypal_packages_ltd['Agency Standard'].id : null,
            },
            {
              ...packages_ltd['Agency Advanced'],
              paypal_id: paypal_packages_ltd['Agency Advanced'] ? paypal_packages_ltd['Agency Advanced'].id : null,
            },
            {
              ...packages_ltd['Agency Guru'],
              paypal_id: paypal_packages_ltd['Agency Guru'] ? paypal_packages_ltd['Agency Guru'].id : null,
            },
          ],
        },
        monthly: {
          Regular: [
            // packages.Free,
            {...packages.Standard, paypal_id: paypal_packages.Standard.id},
            {...packages.Advanced, paypal_id: paypal_packages.Advanced.id},
            {...packages.Guru, paypal_id: paypal_packages.Guru.id},
          ],
          Agency: [
            // packages.Free,
            {...packages['Agency Standard'], paypal_id: paypal_packages['Agency Standard'].id},
            {...packages['Agency Advanced'], paypal_id: paypal_packages['Agency Advanced'].id},
            {...packages['Agency Guru'], paypal_id: paypal_packages['Agency Guru'].id},
          ],
        },
      });
    }
  }, [pageContext.products, currentPackage, showLTD, currentPackageInterval]);

  useEffect(() => {
    //@ts-ignores
    setPr(agency ? pricingAgency : pricing);
  }, [agency]);

  const handleGetPackageClick = (item: any, tiral?: boolean) => {
    setSelectedPackage({...item, trial: !!tiral, name: tiral ? `${item.name} (7-day free trial)` : item.name});
    localStorage.setItem(
      'selected-plan',
      JSON.stringify({
        ...item,
        trial: !!tiral,
        name: tiral ? `${item.name} (7-day free trial)` : item.name,
        ltd: annually && agency && (currentPackage === 'FREE' || currentPackageInterval === 'LTD'),
      }),
    );
    const path = global && global.window && global.window.location && global.window.location.pathname;
    const pathWithSlash = path ? (path.lastIndexOf('/') === path.length - 1 ? path : `${path}/`) : '/pricing_promo/';

    if (user && !user.id) {
      localStorage.setItem('packageInfo', item.id);
      navigate(`${pathWithSlash}#registration`);
      return;
    }
    if (user && !user.verified) {
      setVerifyModal(true);
      return;
    }
    navigate(`${pathWithSlash}checkout/`);
    //window.location.href = 'https://dash.hexometer.com/pricing-v2';
  };

  const handleCloseModal = () => setShowUpgradeContact(false);
  return (
    <>
      <div className="content-wrapper">
        <PageHero
          title="PRICING_PAGE_TITLE"
          // subtitle="PRICING_PAGE_SUBTITLE"
          pricing
          subtitle={
            <>
              <p>Peace of mind for businesses of all sizes</p>
              {annually && agency ? (
                <>
                  <p>
                    <strong style={{fontWeight: 700}}>Kick start your productivity 🚀</strong>
                  </p>
                  <p>
                    <strong style={{fontWeight: 700}}>Get lifetime access </strong> starting from $159
                  </p>
                </>
              ) : (
                <>
                  {/* <p>
                      Our{' '}
                      <Link to="/pricing" style={{color: '#16AC59'}}>
                        regular
                      </Link>{' '}
                      plans are designed to cater for businesses of all sizes featuring comprehensive protection so you
                      can catch problems before they affect your customers.
                    </p>
                    <p>
                      If you are an{' '}
                      <Link to="/pricing/agency" style={{color: '#16AC59'}}>
                        agency
                      </Link>
                      , freelancer or consultant our dedicated agency plans are designed to help scale your business,
                      automate website monitoring and deliver world-class service.
                    </p> */}
                </>
              )}
              {/* <p style={{fontWeight: 600}}>
            To celebrate Hexometer’s birthday and our upcoming Agency Growth Tools update we are launching a last call LTD deal for a limited time on our <Link to='/pricing/agency' style={{color: '#16AC59'}}>Agency plans</Link>.
          </p> */}
            </>
          }
          // subtitle1={
          //   'All our paid plans are covered by our 30-day refund policy so if Hexometer is not a right fit for your business we provide a full refund.'
          // }
          countdown={annually && agency ? <Timer /> : null}
        />

        <Layout>
          <div className="pricing-main">
            <div className={`toggle-and-change-agency-group ${agency ? 'agency' : ''}`}>
              <ToggleRadioButton checked={annually} onChange={() => setAnnually(!annually)} />
              <ToggleButton agency={agency} setAgency={setAgency} />
            </div>
            {!showLTD && user && !ltd && currentPackageInterval === 'LTD' && showUpgradeContact && (
              <div className="text-center all-disabled">
                <span onClick={handleCloseModal}>{closeIcon}</span>
                <img src={pricingIcon} alt="icon" />
                <div>Need to upgrade your plan? </div>
                <div className="support">
                  Please contact{' '}
                  <a
                    href="mailto:support@hexometer.com"
                    target="_blank"
                    className="color-hexometer"
                    aria-label={'mail'}
                    rel="noopener"
                  >
                    support@hexometer.com
                  </a>
                  {'.'}
                </div>
              </div>
            )}
            <div className={`pricing-table ${agency ? 'agency' : ''}`}>
              <div className="vk-card first-column">
                <div className="vk-row head"></div>
                <div className="vk-row bold inline">
                  <span>{item.uniqueHostName} </span>
                  <span className="svg-icon question" data-tip="Unique hostnames">
                    {quetionIcon}
                  </span>
                  {/* <ReactTooltip backgroundColor="#16AC59" /> */}
                </div>
                <div className="vk-row bold">{item.page_limit_per_website}</div>
                {agency && <div className="vk-row bold">{item.agency_growth_tools}</div>}
                <div className="vk-row bold inline">
                  <span>{item.smart_crawl}</span>
                  <span
                    className="svg-icon question"
                    data-tip="Prioritizes important, new or updated pages for faster crawls"
                  >
                    {quetionIcon}
                  </span>
                  {/* <ReactTooltip backgroundColor="#16AC59" multiline /> */}
                </div>

                <div className="vk-row bold section-bg">
                  {item.availability_monitoring && item.availability_monitoring.title}
                </div>
                {true && (
                  <>
                    <div className="vk-row">{item.availability_monitoring.uptime_monitoring_frequency}</div>
                    <div className="vk-row">{item.availability_monitoring.standard_uptime_monitors}</div>
                    <div className="vk-row">{item.availability_monitoring.advanced_monitors}</div>
                    <div className="vk-row">{item.availability_monitoring.whitelabeled_status_pages}</div>
                  </>
                )}
                <div className="vk-row bold section-bg">
                  {item.user_experience_monitoring && item.user_experience_monitoring.title}
                </div>
                {true && (
                  <>
                    <div className="vk-row inline">
                      <span
                        style={{
                          display: 'inline-block',
                          lineHeight: '20px',
                        }}
                      >
                        {item.user_experience_monitoring.spellcheck_and_grammar_checks}
                      </span>
                      <span
                        className="svg-icon question"
                        data-tip="Spelling and grammar checks are available in 31 languages including German, Spanish, French, Italian, Russian and many more."
                      >
                        {quetionIcon}
                        {/* <ReactTooltip backgroundColor="#16AC59" /> */}
                      </span>
                    </div>
                    <div className="vk-row">{item.user_experience_monitoring.in_depth_multi_device_UX_checks}</div>
                  </>
                )}
                <div className="vk-row bold section-bg">
                  {item.performance_monitoring && item.performance_monitoring.title}
                </div>
                {true && (
                  <>
                    <div className="vk-row">{item.performance_monitoring.in_depth_performance_checks}</div>
                  </>
                )}
                <div className="vk-row bold section-bg">{item.health_monitoring && item.health_monitoring.title}</div>
                {true && (
                  <>
                    <div className="vk-row">{item.health_monitoring.broken_links_error_monitoring}</div>
                    <div className="vk-row">{item.health_monitoring.in_depth_W3C_JS_error_checks}</div>
                  </>
                )}
                {/* its for show seo and securitiy when hidded */}
                {!showMore && (
                  <>
                    <div className="vk-row bold section-bg">{item.seo_monitoring && item.seo_monitoring.title}</div>
                    <div className="vk-row bold section-bg">
                      <div className="d-flex w-100">
                        <div>{item.security_monitoring && item.security_monitoring.title}</div>
                        <div className="more ml-auto" onClick={() => setShowMore(!showMore)}>
                          Show more
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {showMore && (
                  <>
                    <div className="vk-row bold section-bg">{item.seo_monitoring && item.seo_monitoring.title}</div>
                    {true && (
                      <>
                        <div className="vk-row">{item.seo_monitoring.search_engine_optimization_audit}</div>
                        <div className="vk-row">{item.seo_monitoring.google_search_console}</div>
                      </>
                    )}
                    <div className="vk-row bold section-bg">
                      {item.security_monitoring && item.security_monitoring.title}
                    </div>
                    {true && (
                      <>
                        <div className="vk-row">{item.security_monitoring.domain_level_web_safe_security_audit}</div>
                        <div className="vk-row">{item.security_monitoring.iP_address_blacklist_checks}</div>
                      </>
                    )}
                    <div className="vk-row bold section-bg">
                      {item.notification_channels_integrations && item.notification_channels_integrations.title}
                    </div>
                    {true && (
                      <>
                        <div className="vk-row">{item.notification_channels_integrations.Email}</div>
                        <div className="vk-row">
                          {item.notification_channels_integrations.Telegram_slack_trello_discord}
                        </div>
                        <div
                          className="vk-row"
                          style={{
                            lineHeight: '21px',
                          }}
                        >
                          {item.notification_channels_integrations.webhooks_zapier_pabbly_integrately}
                        </div>
                        {/* <div className="vk-row">{item.notification_channels_integrations.sms}</div> */}
                      </>
                    )}
                    <div className="vk-row bold section-bg">{item.reporting && item.reporting.title}</div>
                    {true && (
                      <>
                        <div className="vk-row">{item.reporting.exports_CSV_G_Sheets_PDF_whitelabeled}</div>
                      </>
                    )}
                  </>
                )}
                {showMore && (
                  <div className="card-show-more">
                    <span className="more" onClick={() => setShowMore(!showMore)}>
                      Show less
                    </span>
                  </div>
                )}
              </div>

              {hexometerPackages[ltd ? 'ltd' : annually ? 'annual' : 'monthly'] &&
                hexometerPackages[ltd ? 'ltd' : annually ? 'annual' : 'monthly'] &&
                hexometerPackages[ltd ? 'ltd' : annually ? 'annual' : 'monthly'][agency ? 'Agency' : 'Regular'] &&
                hexometerPackages[ltd ? 'ltd' : annually ? 'annual' : 'monthly'][agency ? 'Agency' : 'Regular'].map(
                  (item: any) => {
                    return (
                      <div className="position-relative w-100">
                        {agency && annually && item.name?.toLowerCase() !== 'free' && (
                          <div className="pricing-trigger">
                            <div className={`pricing-trigger-wrapper ${!item.ltdPrice ? 'visibility-hidden' : ''}`}>
                              {' '}
                              LIFETIME DEAL ${item.ltdPrice ? item.ltdPrice : item.price}{' '}
                            </div>
                            <div className={!item.ltdPrice ? 'visibility-hidden' : ''} id="triangle-down"></div>
                          </div>
                        )}
                        <div
                          className={`vk-card ${item.name?.toLowerCase()} ${
                            user && !user.id && !loading
                              ? ''
                              : (!ltd &&
                                  user &&
                                  currentPackageInterval === 'LTD' &&
                                  !(showLTD && annually && agency)) ||
                                item.disabled ||
                                loading ||
                                item.name?.toLowerCase() === 'free'
                              ? 'disabled'
                              : ''
                          }`}
                        >
                          {item.name?.toLowerCase() !== 'free' ? (
                            <div className="vk-row head">
                              {agency && annually ? null : (
                                <div
                                  className={`card-badge ${
                                    (showTrial &&
                                      !annually &&
                                      trialList.includes(item.name.toUpperCase().replace(' ', '_'))) ||
                                    item.flag
                                      ? 'visible'
                                      : 'invisible'
                                  }`}
                                >
                                  {showTrial &&
                                  !annually &&
                                  trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                    ? '7-day free trial'
                                    : item.flag
                                    ? 'Popular'
                                    : ''}
                                </div>
                              )}
                              <span className="package-name">{item.name}</span>
                              <span className="price">
                                <span className={`pr-wrap ${annually && agency && showLTD ? 'line-through' : ''}`}>
                                  <span className="sign">$</span>
                                  <span className="digit">{annually ? item.price / 12 : item.price}</span>
                                  <span className="month">/ mo</span>
                                </span>
                              </span>
                              <div className="" title={pendingPayment ? 'You have pending payment' : ''}>
                                <button
                                  className={`button buy-now ${item.name?.toLowerCase()}`}
                                  onClick={() =>
                                    handleGetPackageClick(
                                      item,
                                      !!(
                                        showTrial &&
                                        !annually &&
                                        trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                      ),
                                    )
                                  }
                                  disabled={pendingPayment}
                                >
                                  {showTrial &&
                                  !annually &&
                                  trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                    ? 'Try for free'
                                    : showLTD && annually && agency
                                    ? 'buy now'
                                    : 'Subscribe'}
                                </button>
                              </div>
                              {!agency && !annually && showTrial && (
                                <div
                                  className={
                                    trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                      ? 'd-flex'
                                      : 'd-flex invisible'
                                  }
                                  style={{lineHeight: '24px'}}
                                >
                                  or{' '}
                                  <div className="clickable-text ml-2" onClick={() => handleGetPackageClick(item)}>
                                    subscribe
                                  </div>
                                </div>
                              )}
                              {annually && showLTD && agency ? (
                                <span className="billed">
                                  <span className="line-through mr-1">${item.price}</span>$
                                  {item.ltdPrice ? item.ltdPrice : item.price} billed{' '}
                                  {showTrial &&
                                  !annually &&
                                  trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                    ? 'after 7-day trial'
                                    : 'upon purchase'}
                                </span>
                              ) : (
                                <span className="billed">
                                  ${item.price} billed{' '}
                                  {showTrial &&
                                  !annually &&
                                  trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                    ? 'after 7-day trial'
                                    : 'upon purchase'}
                                </span>
                              )}
                            </div>
                          ) : (
                            <div className="vk-row head">
                              {/* {item.name?.toLowerCase() === 'advanced' && <div className="stackable_tag">Popular</div>} */}
                              <span className="package-name">
                                {agency ? 'Agency' : ''} {item.name}
                              </span>
                              {/* <div style={{ minHeight: '70px', marginTop: '15px' }}>

                  </div> */}
                              <span className="price">
                                <span className="sign">$</span>
                                <span className="digit">{item.price}</span>
                                <span className="month">/ mo</span>
                              </span>
                              <div className="invisible">
                                <button
                                  className={`button buy-now ${item.packageName?.toLowerCase()}`}
                                  onClick={() => handleGetPackageClick(item)}
                                >
                                  buy now
                                </button>
                              </div>
                              <span className={`billed ${annually && showLTD && agency ? 'pb-billed' : ''}`}>
                                ${item.price} billed upon purchase
                              </span>
                            </div>
                          )}
                          <div className="vk-row">
                            <span className="bold show-when-small">{pr[0].uniqueHostName}</span> {item.uniqueHostName}
                          </div>
                          <div className="vk-row">
                            <span className="bold show-when-small">{pr[0].page_limit_per_website}</span>
                            {item.page_limit_per_website}
                          </div>
                          {agency && (
                            <div className="vk-row">
                              <span className="bold show-when-small">{pr[0].agency_growth_tools}</span>
                              {item.agency_growth_tools}
                            </div>
                          )}
                          <div className="vk-row">
                            <span className="bold show-when-small">{pr[0].smart_crawl}</span>
                            {item.smart_crawl ? checkedCircleIcon : removeCircleIcon}
                          </div>
                          <div className="vk-row bold section-bg">
                            <span className="bold show-when-small">{pr[0].availability_monitoring.title}</span>{' '}
                            {item.availability_monitoring && item.availability_monitoring.title}
                          </div>
                          {true && (
                            <>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].availability_monitoring.uptime_monitoring_frequency}
                                </span>
                                {typeof item.availability_monitoring.uptime_monitoring_frequency === 'boolean'
                                  ? item.availability_monitoring.uptime_monitoring_frequency
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.availability_monitoring.uptime_monitoring_frequency}
                              </div>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].availability_monitoring.standard_uptime_monitors}
                                </span>
                                {typeof item.availability_monitoring.standard_uptime_monitors === 'boolean'
                                  ? item.availability_monitoring.standard_uptime_monitors
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.availability_monitoring.standard_uptime_monitors}
                              </div>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].availability_monitoring.advanced_monitors}
                                </span>
                                {typeof item.availability_monitoring.advanced_monitors === 'boolean'
                                  ? item.availability_monitoring.advanced_monitors
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.availability_monitoring.advanced_monitors}
                              </div>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].availability_monitoring.whitelabeled_status_pages}
                                </span>
                                {typeof item.availability_monitoring.whitelabeled_status_pages === 'boolean'
                                  ? item.availability_monitoring.whitelabeled_status_pages
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.availability_monitoring.whitelabeled_status_pages}
                              </div>
                            </>
                          )}
                          <div className="vk-row bold section-bg">
                            <span className="bold show-when-small">{pr[0].user_experience_monitoring.title}</span>{' '}
                            {item.user_experience_monitoring && item.user_experience_monitoring.title}
                          </div>
                          {true && (
                            <>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].user_experience_monitoring.spellcheck_and_grammar_checks}
                                </span>
                                {typeof item.user_experience_monitoring.spellcheck_and_grammar_checks === 'boolean'
                                  ? item.user_experience_monitoring.spellcheck_and_grammar_checks
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.user_experience_monitoring.spellcheck_and_grammar_checks}
                              </div>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].user_experience_monitoring.in_depth_multi_device_UX_checks}
                                </span>
                                {typeof item.user_experience_monitoring.in_depth_multi_device_UX_checks === 'boolean'
                                  ? item.user_experience_monitoring.in_depth_multi_device_UX_checks
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.user_experience_monitoring.in_depth_multi_device_UX_checks}
                              </div>
                            </>
                          )}
                          <div className="vk-row bold section-bg">
                            <span className="bold show-when-small">{pr[0].performance_monitoring.title}</span>{' '}
                            {item.performance_monitoring && item.performance_monitoring.title}
                          </div>
                          {true && (
                            <>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].performance_monitoring.in_depth_performance_checks}
                                </span>
                                {typeof item.performance_monitoring.in_depth_performance_checks === 'boolean'
                                  ? item.performance_monitoring.in_depth_performance_checks
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.performance_monitoring.in_depth_performance_checks}
                              </div>
                            </>
                          )}
                          <div className="vk-row bold section-bg">
                            <span className="bold show-when-small">{pr[0].health_monitoring.title}</span>
                            {item.health_monitoring && item.health_monitoring.title}
                          </div>
                          {true && (
                            <>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].health_monitoring.broken_links_error_monitoring}
                                </span>
                                {typeof item.health_monitoring.broken_links_error_monitoring === 'boolean'
                                  ? item.health_monitoring.broken_links_error_monitoring
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.health_monitoring.broken_links_error_monitoring}
                              </div>
                              <div className="vk-row">
                                <span className="bold show-when-small">
                                  {pr[0].health_monitoring.in_depth_W3C_JS_error_checks}
                                </span>
                                {typeof item.health_monitoring.in_depth_W3C_JS_error_checks === 'boolean'
                                  ? item.health_monitoring.in_depth_W3C_JS_error_checks
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.health_monitoring.in_depth_W3C_JS_error_checks}
                              </div>
                            </>
                          )}
                          {/* its for show seo and securitiy when hidded */}
                          {!showMore && (
                            <>
                              <div className="vk-row bold section-bg">
                                <span className="bold show-when-small">{pr[0].seo_monitoring.title}</span>
                                {typeof item.seo_monitoring.google_search_console === 'boolean'
                                  ? item.seo_monitoring.google_search_console
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.seo_monitoring.google_search_console}
                              </div>
                              <div className="vk-row bold section-bg">
                                <span className="bold show-when-small">{pr[0].security_monitoring.title}</span>
                                {typeof item.security_monitoring.domain_level_web_safe_security_audit === 'boolean'
                                  ? item.security_monitoring.domain_level_web_safe_security_audit
                                    ? checkedCircleIcon
                                    : removeCircleIcon
                                  : item.security_monitoring.domain_level_web_safe_security_audit}
                              </div>
                            </>
                          )}
                          {showMore && (
                            <>
                              <div className="vk-row bold section-bg">
                                <span className="bold show-when-small">{pr[0].seo_monitoring.title}</span>
                                {item.seo_monitoring && item.seo_monitoring.title}
                              </div>
                              {true && (
                                <>
                                  <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].seo_monitoring.search_engine_optimization_audit}
                                    </span>
                                    {typeof item.seo_monitoring.search_engine_optimization_audit === 'boolean'
                                      ? item.seo_monitoring.search_engine_optimization_audit
                                        ? checkedCircleIcon
                                        : removeCircleIcon
                                      : item.seo_monitoring.search_engine_optimization_audit}
                                  </div>
                                  <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].seo_monitoring.google_search_console}
                                    </span>
                                    {typeof item.seo_monitoring.google_search_console === 'boolean'
                                      ? item.seo_monitoring.google_search_console
                                        ? checkedCircleIcon
                                        : removeCircleIcon
                                      : item.seo_monitoring.google_search_console}
                                  </div>
                                </>
                              )}
                              <div className="vk-row bold section-bg">
                                <span className="bold show-when-small">{pr[0].security_monitoring.title}</span>
                                {item.security_monitoring && item.security_monitoring.title}
                              </div>
                              {true && (
                                <>
                                  <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].security_monitoring.domain_level_web_safe_security_audit}
                                    </span>
                                    {typeof item.security_monitoring.domain_level_web_safe_security_audit === 'boolean'
                                      ? item.security_monitoring.domain_level_web_safe_security_audit
                                        ? checkedCircleIcon
                                        : removeCircleIcon
                                      : item.security_monitoring.domain_level_web_safe_security_audit}
                                  </div>
                                  <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].security_monitoring.iP_address_blacklist_checks}
                                    </span>
                                    {typeof item.security_monitoring.iP_address_blacklist_checks === 'boolean'
                                      ? item.security_monitoring.iP_address_blacklist_checks
                                        ? checkedCircleIcon
                                        : removeCircleIcon
                                      : item.security_monitoring.iP_address_blacklist_checks}
                                  </div>
                                </>
                              )}
                              <div className="vk-row bold section-bg">
                                {' '}
                                <span className="bold show-when-small">
                                  {pr[0].notification_channels_integrations.title}
                                </span>
                                {item.notification_channels_integrations &&
                                  item.notification_channels_integrations.title}
                              </div>
                              {true && (
                                <>
                                  <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].notification_channels_integrations.Email}
                                    </span>
                                    {typeof item.notification_channels_integrations.Email === 'boolean'
                                      ? item.notification_channels_integrations.Email
                                        ? checkedCircleIcon
                                        : removeCircleIcon
                                      : item.notification_channels_integrations.Email}
                                  </div>
                                  <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].notification_channels_integrations.Telegram_slack_trello_discord}
                                    </span>
                                    {typeof item.notification_channels_integrations.Telegram_slack_trello_discord ===
                                    'boolean'
                                      ? item.notification_channels_integrations.Telegram_slack_trello_discord
                                        ? checkedCircleIcon
                                        : removeCircleIcon
                                      : item.notification_channels_integrations.Telegram_slack_trello_discord}
                                  </div>
                                  <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].notification_channels_integrations.webhooks_zapier_pabbly_integrately}
                                    </span>
                                    {typeof item.notification_channels_integrations
                                      .webhooks_zapier_pabbly_integrately === 'boolean'
                                      ? item.notification_channels_integrations.webhooks_zapier_pabbly_integrately
                                        ? checkedCircleIcon
                                        : removeCircleIcon
                                      : item.notification_channels_integrations.webhooks_zapier_pabbly_integrately}
                                  </div>
                                  {/* <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].notification_channels_integrations.sms}
                                    </span>
                                    {typeof item.notification_channels_integrations.sms === 'boolean'
                                      ? item.notification_channels_integrations.sms
                                        ? checkedCircleIcon
                                        : removeCircleIcon
                                      : item.notification_channels_integrations.sms}
                                  </div> */}
                                </>
                              )}
                              <div className="vk-row bold section-bg">
                                <span className="bold show-when-small">{pr[0].reporting.title}</span>
                                {item.reporting && item.reporting.title}
                              </div>
                              {true && (
                                <>
                                  <div className="vk-row">
                                    <span className="bold show-when-small">
                                      {pr[0].reporting.exports_CSV_G_Sheets_PDF_whitelabeled}
                                    </span>
                                    {item.reporting.exports_CSV_G_Sheets_PDF_whitelabeled
                                      ? checkedCircleIcon
                                      : removeCircleIcon}
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {showMore && (
                            <div className="card-buy-now w-100 px-3">
                              <button
                                className={`button buy-now ${item.packageName?.toLowerCase()} ${
                                  showTrial &&
                                  !annually &&
                                  trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                    ? 'trial-btn'
                                    : ''
                                }`}
                                onClick={() =>
                                  handleGetPackageClick(
                                    item,
                                    !!(
                                      showTrial &&
                                      !annually &&
                                      trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                    ),
                                  )
                                }
                              >
                                {showTrial && !annually && trialList.includes(item.name.toUpperCase().replace(' ', '_'))
                                  ? 'Try for free'
                                  : showLTD && annually && agency
                                  ? 'buy now'
                                  : 'Subscribe'}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  },
                )}
            </div>
            <p className="f_size_14 ml-2">
              We offer a discount to educational and non-profit organizations. Please contact us for options.
            </p>
          </div>
        </Layout>
      </div>
      {verifyModal && (
        <Modal
          isOpen={verifyModal}
          modalToggle={() => {}}
          modalFooter={
            <Button className="w-100" onClick={() => setVerifyModal(false)}>
              Close
            </Button>
          }
        >
          <EmailVerification />
        </Modal>
      )}
    </>
  );
};

export default Pricing;
